import React, { useEffect, FunctionComponent, PropsWithChildren } from 'react';
import Text from '@amzn/meridian/text';
import {
  ErrorBoundary as ReactErrorBoundary,
  useErrorBoundary,
} from 'react-error-boundary';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button';
import { ErrorLogo } from 'src/assets/ErrorLogo';
import { useProfileInfoStore } from 'src/store/profile-info';
import { GAT_STRINGS } from 'src/features/auth/constants/static';
import { useLocation } from 'react-router-dom';
import { usePrevious } from 'src/hooks';

const ErrorComponent = () => {
  const { bundle } = useProfileInfoStore();
  const { resetBoundary } = useErrorBoundary();
  const location = useLocation();
  const prevLocation = usePrevious(location);
  useEffect(() => {
    if (prevLocation && location.pathname !== prevLocation.pathname) {
      resetBoundary();
    }
  }, [location]);

  return (
    <div>
      <Row spacingInset="600">
        <ErrorLogo />
        <Text>{bundle.formatMessage(GAT_STRINGS.GAT_ERROR_BOUNDARY_TEXT)}</Text>
        <Button onClick={() => window.location.reload()}>Reset</Button>
      </Row>
    </div>
  );
};

export const ErrorBoundary: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  return (
    <ReactErrorBoundary FallbackComponent={ErrorComponent}>
      {children}
    </ReactErrorBoundary>
  );
};
