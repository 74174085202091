import { LocalizationContextBuilder } from '@amzn/arb-tools';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import arbManifest from '../../build/i18n/arbManifest';
import { COGNITO_ATTRIBUTES } from 'src/features/profile/constants';

export const DEFAULT_LOCALE = 'en-US';
export const DEFAULT_LC_BUILDER = new LocalizationContextBuilder().withLocale(
  DEFAULT_LOCALE,
);

export const bundle_name = 'gat_translations_strings';
export const mbmOptions = {
  arbManifest,
  defaultLocalizationContext: DEFAULT_LC_BUILDER.withLocale(DEFAULT_LOCALE)
    .withDefaultLocale(DEFAULT_LOCALE)
    .build(),
  urlPrefix: '/i18n',
};

export const getLocale = (authTokenPayload: any) => {
  const locale = `${authTokenPayload[COGNITO_ATTRIBUTES.LANGUAGE_ATTR]}-${
    authTokenPayload[COGNITO_ATTRIBUTES.COUNTRY_ATTR]
  }`;
  const languageCode = authTokenPayload[COGNITO_ATTRIBUTES.LANGUAGE_ATTR];
  const ret = arbManifest.endpointMap?.[locale]?.[bundle_name]
    ? locale
    : arbManifest.endpointMap?.[languageCode]?.[bundle_name]
    ? languageCode
    : DEFAULT_LOCALE;
  return ret;
};
