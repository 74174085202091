import { OrchestratorApiService } from 'src/service';
import { BACKEND_PATHS } from 'src/constants/backend-paths';
import { GetUserRolesResponse } from 'src/features/home/types';

const APPROVED_USER_STATUS = 'APPROVED';

/**
 * This method is used to fetch the list of sites where
 * the current user has site admin access.
 */

export const getSiteAdminSites = async (): Promise<Set<string>> => {
  const response = await OrchestratorApiService.get(BACKEND_PATHS.USER_ROLES);
  const data: GetUserRolesResponse = response.data;
  const siteList = new Set<string>();
  data.userRoles.forEach((roleInfo) => {
    if (
      roleInfo.status === APPROVED_USER_STATUS &&
      (roleInfo.attributes?.isSiteAdminRole === true ||
        roleInfo.attributes?.isSiteAdminRole === 'true')
    ) {
      if (roleInfo.attributes.SiteId) {
        siteList.add(roleInfo.attributes.SiteId);
      }
    }
  });
  return siteList;
};
