import { BACKEND_PATHS } from 'src/constants/backend-paths';
import { OrchestratorApiService } from 'src/service';
import { useMutation } from '@tanstack/react-query';

interface Options {
  roleId: string;
  siteId: string;
  userId: string;
}

const grantRoleRequest = async (options: Options) => {
  const response = await OrchestratorApiService.post(
    BACKEND_PATHS.GRANT_ROLE,
    options,
  );
  return response.data;
};

export const useGrantRoleRequest = () => {
  return useMutation({
    mutationFn: grantRoleRequest,
  });
};
