import { fetchAuthSession } from 'aws-amplify/auth';
import { useEffect, useState } from 'react';

export const useAuthTokenPayload = () => {
  const [authTokenPayload, setAuthTokenPayload] = useState<{
    data: any;
    loading: boolean;
  }>({ data: undefined, loading: true });

  useEffect(() => {
    fetchAuthSession()
      .then((authSession) => {
        setAuthTokenPayload({
          data: authSession.tokens?.idToken?.payload,
          loading: false,
        });
      })
      .catch(() => {
        setAuthTokenPayload({
          data: undefined,
          loading: false,
        });
      });
  }, []);

  const refreshAuthTokenPayload = async () => {
    setAuthTokenPayload({ ...authTokenPayload, loading: true });
    await fetchAuthSession({ forceRefresh: true }).then((authSession) => {
      setAuthTokenPayload({
        data: authSession.tokens?.idToken?.payload,
        loading: false,
      });
    });
  };
  return { authTokenPayload, refreshAuthTokenPayload };
};
