import { axios } from 'src/lib/axios';
import { fetchAuthSession } from 'aws-amplify/auth';
import { APPLICATION_JSON, CONTENT_TYPE } from 'src/constants/backend-paths';

interface ApiClient {
  get(path: string): Promise<any>;
  post(
    path: string,
    body: any,
    abortController?: AbortController,
  ): Promise<any>;
  put(path: string, body: any): Promise<any>;
  patch(path: string, body: any): Promise<any>;
  delete(path: string): Promise<any>;
}

export const OrchestratorApiService: ApiClient = {
  async get(path) {
    const authToken = await fetchAuthSession().then((authSession) =>
      authSession.tokens?.idToken?.toString(),
    );
    return axios.get(path, {
      headers: {
        [CONTENT_TYPE]: APPLICATION_JSON,
        Authorization: authToken,
      },
    });
  },

  async post(path, body, abortController: AbortController) {
    const authToken = await fetchAuthSession().then((authSession) =>
      authSession.tokens?.idToken?.toString(),
    );
    return axios.post(path, body, {
      headers: {
        [CONTENT_TYPE]: APPLICATION_JSON,
        Authorization: authToken,
      },
      signal: abortController?.signal,
    });
  },

  async put(path, body) {
    const authToken = await fetchAuthSession().then((authSession) =>
      authSession.tokens?.idToken?.toString(),
    );
    return axios.put(path, body, {
      headers: {
        [CONTENT_TYPE]: APPLICATION_JSON,
        Authorization: authToken,
      },
    });
  },

  async patch(path, body) {
    const authToken = await fetchAuthSession().then((authSession) =>
      authSession.tokens?.idToken?.toString(),
    );
    return axios.patch(path, body, {
      headers: {
        [CONTENT_TYPE]: APPLICATION_JSON,
        Authorization: authToken,
      },
    });
  },

  async delete(path) {
    const authToken = await fetchAuthSession().then((authSession) =>
      authSession.tokens?.idToken?.toString(),
    );
    return axios.delete(path, {
      headers: {
        [CONTENT_TYPE]: APPLICATION_JSON,
        Authorization: authToken,
      },
    });
  },
};
