export const PAGE_PATHS = {
  WELCOME: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  HOME: '/',
  PROFILE: '/profile',
  EDIT_PROFILE: '/profile/edit',
  REQUEST_ACCESS: '/access/request',
  VIEW_ACCESS: '/access/view',
  APPROVE_ACCESS_REQUESTS: '/access/approve',
  AUDIT_QUESTION_PAGE_VIEW: (auditId: string, auditAction: string) =>
    `/audit/${auditId}/${auditAction}`,
  AUDIT_QUESTION_PAGE_VIEW_FILTER: (
    auditId: string,
    auditAction: string,
    filter: string,
  ) => `/audit/${auditId}/${auditAction}?filter=${filter}`,
  ADVANCED_SEARCH: '/audit-search',
  AUDIT_QUESTION_PAGE: '/audit/:auditId/:auditAction',
  USER_ACCESS: '/access/user',
  GRANT_ACCESS: '/access/grant',
  SITE_INFO: '/site-information',
  VENDOR_MANAGEMENT: '/supplier-management',
  SUPPLIER_INFO_PAGE_VIEW: (coupaId: string) => `/supplier/${coupaId}`,
  SUPPLIER_INFO_PAGE: `/supplier/:coupaId`,
  PURCHASE_ORDERS: '/purchase-orders',
};
