import { useToastStore } from 'src/store/toast-store';
import Toaster from '@amzn/meridian/toaster';
import Alert from '@amzn/meridian/alert';

const ToastListener = () => {
  const { toasts, removeToast } = useToastStore();

  return (
    <Toaster toasts={toasts} onCloseToast={removeToast}>
      {(toast) => (
        <Alert toast={true} type={toast.type} onClose={toast.onClose}>
          {toast.message}
        </Alert>
      )}
    </Toaster>
  );
};

export default ToastListener;
