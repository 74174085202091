export const CONTENT_TYPE = 'Content-Type';
export const CONTENT_DISPOSITION = 'Content-Disposition';
export const APPLICATION_JSON = 'application/json';
export const BINARY_OCTET_STREAM = 'binary/octet-stream';

export const BACKEND_PATHS = {
  REQUEST_ROLE: '/roles/requests',
  ROLE_CONFIG: `/configs/roles?status=ACTIVE`,
  GET_SITES_AND_LANGUAGES_CONFIG: (country: string) =>
    `/configs/sites-languages?country=${country}`,
  USER_ROLES: '/roles',
  USER_ROLES_WITH_POC: '/roles?withPOC=true',
  USER_ROLES_EXTERNAL: '/roles/external',
  GET_INTERNAL_AUDITS_FOR_INTERNAL_USERS: (filterSite: string) =>
    `/audits?siteId=${filterSite}&status=DRAFT&status=IN_PROGRESS&assignedUserType=Internal`,
  GET_EXTERNAL_AUDITS_FOR_INTERNAL_USERS: (
    filterSite: string,
    startDate: string,
    endDate: string,
  ) =>
    `/audits?siteId=${filterSite}&status=SUBMITTED&assignedUserType=External&activeTillStartDate=${startDate}&activeTillEndDate=${endDate}`,
  GET_INTERNAL_AUDITS_FOR_EXTERNAL_USERS: (
    filterSite: string,
    filterStartDate: string,
    filterEndDate: string,
  ) =>
    `/audits?siteId=${filterSite}&status=SUBMITTED&activeTillStartDate=${filterStartDate}&activeTillEndDate=${filterEndDate}&assignedUserType=Internal`,
  GET_EXTERNAL_AUDITS_FOR_EXTERNAL_USERS: (filterSite: string) =>
    `/audits?siteId=${filterSite}&status=DRAFT&status=IN_PROGRESS&assignedUserType=External`,
  GET_AUDIT_INFO: (auditId: string, language: string) =>
    `/audits/${auditId}?languageCode=${language}`,
  CHECK_ACCESS: (
    action: string,
    resource: string,
    resourceType: string,
    siteId: string,
    auditRoleId: string,
  ) =>
    `/access?action=${action}&resource=${resource}&resourceType=${resourceType}&siteId=${siteId}&auditRoleId=${auditRoleId}`,
  GET_ROLE_REQUESTS: (filterSite: string, status: string) =>
    `/roles/requests?siteId=${filterSite}&status=${status}`,
  PROCESS_ROLE_REQUEST: (recordId: string) => `/roles/requests/${recordId}`,
  GET_AUDITS_WITH_FILTERS: (
    filterSite: string,
    filterStartDate: string,
    filterEndDate: string,
    filterTemplateType: string,
    filterStatus: string,
    filterExemption: boolean | null,
    filterAssignedUserType: string,
  ) =>
    `/audits?siteId=${filterSite}&status=${filterStatus}&activeTillStartDate=${filterStartDate}&activeTillEndDate=${filterEndDate}&templateType=${filterTemplateType}&assignedUserType=${filterAssignedUserType}${
      filterExemption != null ? `&exempted=${filterExemption}` : ''
    }`,
  GET_AUDITS_WITH_FILTERS_NOT_STARTED: (
    filterSite: string,
    filterStartDate: string,
    filterEndDate: string,
    filterTemplateType: string,
    filterExemption: boolean | null,
    filterAssignedUserType: string,
  ) =>
    `/audits?siteId=${filterSite}&activeTillStartDate=${filterStartDate}&activeTillEndDate=${filterEndDate}&templateType=${filterTemplateType}&status=DRAFT&status=SCHEDULED&assignedUserType=${filterAssignedUserType}${
      filterExemption != null ? `&exempted=${filterExemption}` : ''
    }`,
  GET_AUDIT_TYPES: (filterSite: string) =>
    `/configs/sites/${filterSite}/audits`,
  GET_EXTERNAL_USER_SITES: `/supplier/user/sites`,
  GET_INTERNAL_USER_SITES: `/roles`,
  SAVE_AUDIT_INFO: (auditId: string) => `/audits/${auditId}`,
  GET_USER_REQUESTED_ROLES: (userId: string) =>
    `/roles/requests?userId=${userId}`,
  GET_COUNTRIES: '/configs/countries',
  GRANT_ROLE: '/roles',
  CREATE_SUPPLIER: '/configs/supplier',
  INVITE_SUPPLIER_USER: (coupaId: string) => `/supplier/${coupaId}/user`,
  OFFBOARD_SUPPLIER_USER: (coupaId: string, email: string) =>
    `/supplier/${coupaId}/user?email=${email}`,
  ADD_SITE_TO_SUPPLIER_EMPLOYEE: (coupaId: string) =>
    `/supplier/${coupaId}/user/site`,
  ADD_SUPPLIER_SERVICE: '/configs/site-supplier',
  DEACTIVATE_SUPPLIER_SERVICE: (coupaId: string, site: string, audit: string) =>
    `/configs/site-supplier/${coupaId}?siteId=${site}&auditType=${audit}`,
  GET_SUPPLIER: (coupaId: string) => `/configs/supplier/${coupaId}`,
  GET_SITE_INFORMATION: (filterSite: string) =>
    `/configs/sites/${filterSite}/site-config`,
  GET_DOWNLOAD_PRESIGNED_URL: (qnaInstanceId: string, attachmentId: string) =>
    `/audits/${qnaInstanceId}/attachments/${attachmentId}`,
  GET_UPLOAD_PRESIGNED_URL: (qnaInstanceId: string) =>
    `/audits/${qnaInstanceId}/attachments`,
  DELETE_ATTACHMENT: (qnaInstanceId: string, attachmentId: string) =>
    `/audits/${qnaInstanceId}/attachments/${attachmentId}`,
  GET_SUPPLIER_LIST: `/configs/suppliers`,
  GET_SUPPLIER_USERS: (coupaId: string) => `/suppliers/${coupaId}/users`,
  GET_SUPPLIER_SITES_AUDITS: (coupaId: string) =>
    `/configs/suppliers/${coupaId}/sites`,
  GET_FAILURE_REMEDIATON: (
    qnaInstanceId: string,
    failureRemediationId: string,
  ) => `/audits/${qnaInstanceId}/failure/${failureRemediationId}`,
  EXEMPT_AUDITS: '/audits/exemptions',
  SAVE_FAILURE_REMEDIATION: (
    qnaInstanceId: string,
    failureRemediationId: string,
  ) => `/audits/${qnaInstanceId}/failure/${failureRemediationId}`,
  GET_FAILURE_REMEDIATION_UPLOAD_URL: (
    qnaInstanceId: string,
    failureRemediationId: string,
  ) => `/audits/${qnaInstanceId}/failure/${failureRemediationId}/attachments`,
};
