import { useQuery } from '@tanstack/react-query';
import { ExtractFnReturnType } from 'src/lib/react-query';
import { OrchestratorApiService } from 'src/service';
import {
  GetSiteList,
  GetSitesAndLanguageConfigResponse,
} from 'src/features/access/types/get-site-list';
import { fetchAuthSession } from 'aws-amplify/auth';
import { BACKEND_PATHS } from 'src/constants/backend-paths';

export const getSiteList = async (): Promise<GetSiteList> => {
  const country = await fetchAuthSession().then(
    (authSession) => authSession.tokens?.idToken?.payload['custom:country'],
  );
  if (country) {
    const response = await OrchestratorApiService.get(
      BACKEND_PATHS.GET_SITES_AND_LANGUAGES_CONFIG(country as string),
    );
    const data: GetSitesAndLanguageConfigResponse = response.data;
    return data.config[0].sites as GetSiteList;
  }
  return [] as GetSiteList;
};

type QueryFnType = typeof getSiteList;

export const useSiteList = () => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['getStaticSiteList'],
    queryFn: () => getSiteList(),
  });
};
