import { useQuery } from '@tanstack/react-query';
import { ExtractFnReturnType } from 'src/lib/react-query';
import { OrchestratorApiService } from 'src/service';
import { GetUserRolesResponse } from 'src/features/home/types';
import { BACKEND_PATHS } from 'src/constants/backend-paths';
import { checkUserFacingStatus } from '../utils';

/**
 * Get all access of the logged in user, filter for user facing roles.
 */
const getUserAccess = async () => {
  const response = await OrchestratorApiService.get(
    BACKEND_PATHS.USER_ROLES_WITH_POC,
  );
  const data: GetUserRolesResponse = response.data;
  data.userRoles = data.userRoles.filter(checkUserFacingStatus);
  return data;
};

type QueryFnType = typeof getUserAccess;

export const useGetUserAccess = (isInternal: boolean) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['getUserAccess'],
    queryFn: () => getUserAccess(),
    enabled: isInternal,
  });
};
