import { create } from 'zustand';

interface ISideMenuStore {
  isSideMenuOpen: boolean;
  setIsSideMenuOpen: (newSideMenuState: boolean) => void;
  flipIsSideMenuOpen: () => void;
}

export const useSideMenuStore = create<ISideMenuStore>((set, get) => ({
  isSideMenuOpen: true,
  setIsSideMenuOpen: (newSideMenuState: boolean) => {
    set({ isSideMenuOpen: newSideMenuState });
  },
  flipIsSideMenuOpen: () => {
    const isSideMenuOpenState = get().isSideMenuOpen;
    set({ isSideMenuOpen: !isSideMenuOpenState });
  },
}));
