import { appLayoutContentId } from '@amzn/meridian/app-layout';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ResetScroll = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const appContent = document.getElementById(appLayoutContentId);
    if (appContent) {
      appContent.scrollTop = 0;
    }
  }, [pathname]);

  return null;
};

export default ResetScroll;
