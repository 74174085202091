import { fetchAuthSession } from 'aws-amplify/auth';
import { useEffect, useState } from 'react';

export const useIsInternal = () => {
  const [isInternal, setIsInternal] = useState<{
    data: boolean;
    isLoading: boolean;
  }>({
    data: false,
    isLoading: true,
  });

  useEffect(() => {
    fetchAuthSession().then((authSession) => {
      const payload = authSession.tokens?.idToken?.payload;
      // Amplify types aren't expressive enough to fetch the providerName
      // Adding this eslint disable here to fetch identity.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /* @ts-ignore */
      const providerName = payload['identities']?.[0]?.['providerName'];
      setIsInternal({
        data: providerName === 'AmazonFederate',
        isLoading: false,
      });
    });
  }, []);
  return isInternal;
};
