import Table, { TableCell, TableRow } from '@amzn/meridian/table';
import React, { useState } from 'react';
import { useApprovalRequests } from '../api/get-approval-requests';
import { Loader } from 'src/components';
import Box from '@amzn/meridian/box';
import Text from '@amzn/meridian/text';
import { APPROVE_ACCESS_PAGE_STRINGS } from '../constants';
import { UserInfo } from 'src/features/home/types';
import Icon from '@amzn/meridian/icon';
import { css } from '@emotion/css';
import Column from '@amzn/meridian/column';
import Pagination from '@amzn/meridian/pagination';
import ButtonGroup, { ButtonOption } from '@amzn/meridian/button-group';
import checkCircle from '@amzn/meridian-tokens/base/icon/check-circle';
import closeCircle from '@amzn/meridian-tokens/base/icon/close-circle';
import { useProcessRoleRequest } from '../api/process-role-request';
import { useToastStore } from 'src/store/toast-store';
import { useAuthTokenPayload, useIsMobile } from 'src/hooks';
import { BundledLocalizationProps } from 'src/interfaces/BundleInterface';
import { useProfileInfoStore } from 'src/store/profile-info';
import { fetchErrorMessage } from 'src/lib/react-query';

const itemsPerPage = 10;

const ApprovalRequestsTableHeader = ({ bundle }: BundledLocalizationProps) => (
  <TableRow>
    <TableCell width={'40%'}>
      {bundle.formatMessage(APPROVE_ACCESS_PAGE_STRINGS.USER)}
    </TableCell>
    <TableCell width={'20%'}>
      {bundle.formatMessage(APPROVE_ACCESS_PAGE_STRINGS.ROLE)}
    </TableCell>
    <TableCell width={'20%'}>
      {bundle.formatMessage(APPROVE_ACCESS_PAGE_STRINGS.ACTION)}
    </TableCell>
  </TableRow>
);

const ApprovalRequestsTableRow: React.FC<
  {
    userId: string;
    role: string;
    site: string;
    refetch: any;
    recordId: string;
    status: string;
  } & BundledLocalizationProps
> = ({ userId, role, site, refetch, recordId, status, bundle }) => {
  const { addToast } = useToastStore();
  const mutation = useProcessRoleRequest();
  const isMobile = useIsMobile();
  const { authTokenPayload } = useAuthTokenPayload();

  return !authTokenPayload.loading ? (
    <TableRow>
      <TableCell>{userId}</TableCell>
      <TableCell>{role}</TableCell>
      <TableCell>
        <ButtonGroup>
          {status === 'PENDING' && (
            <>
              <ButtonOption
                value={'APPROVE'}
                onClick={() =>
                  mutation.mutate(
                    {
                      siteId: site,
                      recordId: recordId,
                      consensus: 'APPROVED',
                    },
                    {
                      onSuccess: (data: {
                        isSuccessful: boolean;
                        reason: string;
                      }) => {
                        addToast({
                          id: recordId,
                          type: data.isSuccessful ? 'success' : 'error',
                          message:
                            data.reason ??
                            bundle.formatMessage(
                              APPROVE_ACCESS_PAGE_STRINGS.PROCESS_SUCCESFUL,
                            ),
                          timeout: 5000,
                        });
                        refetch();
                      },
                      onError: (error) => {
                        addToast({
                          id: recordId,
                          type: 'error',
                          message:
                            fetchErrorMessage(error) ??
                            bundle.formatMessage(
                              APPROVE_ACCESS_PAGE_STRINGS.PROCESS_FAILURE,
                            ),
                          timeout: 5000,
                        });
                      },
                    },
                  )
                }
              >
                {!isMobile &&
                  bundle.formatMessage(APPROVE_ACCESS_PAGE_STRINGS.APPROVE)}
                <Icon tokens={checkCircle} />
              </ButtonOption>
              <ButtonOption
                value={'REJECT'}
                onClick={() =>
                  mutation.mutate(
                    {
                      siteId: site,
                      recordId: recordId,
                      consensus: 'REJECTED',
                    },
                    {
                      onSuccess: (data: {
                        isSuccessful: boolean;
                        reason: string;
                      }) => {
                        addToast({
                          id: recordId,
                          type: data.isSuccessful ? 'success' : 'error',
                          message:
                            data.reason ??
                            bundle.formatMessage(
                              APPROVE_ACCESS_PAGE_STRINGS.PROCESS_SUCCESFUL,
                            ),
                          timeout: 5000,
                        });
                        refetch();
                      },
                      onError: () => {
                        addToast({
                          id: recordId,
                          type: 'error',
                          message: bundle.formatMessage(
                            APPROVE_ACCESS_PAGE_STRINGS.PROCESS_FAILURE,
                          ),
                          timeout: 5000,
                        });
                      },
                    },
                  )
                }
              >
                {!isMobile &&
                  bundle.formatMessage(APPROVE_ACCESS_PAGE_STRINGS.REJECT)}
                <Icon tokens={closeCircle} />
              </ButtonOption>
            </>
          )}
          {status === 'APPROVED' && (
            <>
              <ButtonOption
                value={'REVOKED'}
                onClick={() =>
                  mutation.mutate(
                    {
                      siteId: site,
                      recordId: recordId,
                      consensus: 'REVOKED',
                    },
                    {
                      onSuccess: (data: {
                        isSuccessful: boolean;
                        reason: string;
                      }) => {
                        addToast({
                          id: recordId,
                          type: data.isSuccessful ? 'success' : 'error',
                          message:
                            data.reason ??
                            bundle.formatMessage(
                              APPROVE_ACCESS_PAGE_STRINGS.PROCESS_SUCCESFUL,
                            ),
                          timeout: 5000,
                        });
                        if (userId === authTokenPayload.data['email']) {
                          window.location.replace('/');
                        } else {
                          refetch();
                        }
                      },
                      onError: () => {
                        addToast({
                          id: recordId,
                          type: 'error',
                          message: bundle.formatMessage(
                            APPROVE_ACCESS_PAGE_STRINGS.PROCESS_FAILURE,
                          ),
                          timeout: 5000,
                        });
                      },
                    },
                  )
                }
              >
                {!isMobile &&
                  bundle.formatMessage(APPROVE_ACCESS_PAGE_STRINGS.REVOKE)}
                <Icon tokens={closeCircle} />
              </ButtonOption>
            </>
          )}
        </ButtonGroup>
      </TableCell>
    </TableRow>
  ) : (
    <></>
  );
};

export const ApproveAccessList: React.FC<{
  filterSite: string;
  status: string;
}> = ({ filterSite, status }) => {
  const {
    data: approvalRequests,
    isLoading: isApprovalRequestsLoading,
    error: approvalRequestsError,
    refetch,
  } = useApprovalRequests(filterSite as string, status);

  const numberOfPages = Math.ceil(
    (approvalRequests?.userRoles?.length ?? 0) / itemsPerPage,
  );
  const [currentPage, setCurrentPage] = useState(1);
  const firstVisibleIndex = (currentPage - 1) * itemsPerPage;
  const lastVisibleIndex = firstVisibleIndex + itemsPerPage;
  const { bundle } = useProfileInfoStore();
  return (
    <>
      {isApprovalRequestsLoading ? (
        <Loader />
      ) : approvalRequestsError ? (
        <Box spacingInset={'200'}>
          <Text type="b300">
            {bundle.formatMessage(
              APPROVE_ACCESS_PAGE_STRINGS.APPROVAL_REQUESTS_ERROR,
            )}
          </Text>
        </Box>
      ) : approvalRequests?.userRoles.length == 0 ? (
        <Box spacingInset={'200'}>
          <Text type="b300">
            {bundle.formatMessage(
              APPROVE_ACCESS_PAGE_STRINGS.NO_REQUESTS_FOUND,
            )}
          </Text>
        </Box>
      ) : (
        <div
          className={css`
            overflow-x: auto;
            max-width: 100%;
          `}
        >
          <Table
            rowComponents={[
              ApprovalRequestsTableHeader,
              ApprovalRequestsTableRow,
            ]}
            showDividers={true}
            showStripes={true}
            headerRows={1}
            headerColumns={1}
          >
            {approvalRequests?.userRoles?.length && (
              <ApprovalRequestsTableHeader bundle={bundle} />
            )}{' '}
            {approvalRequests?.userRoles
              ?.slice(firstVisibleIndex, lastVisibleIndex)
              .map((approvalInfo: UserInfo) => (
                <ApprovalRequestsTableRow
                  key={
                    approvalInfo.userId +
                    approvalInfo.role +
                    approvalInfo.roleId
                  }
                  userId={approvalInfo.userId}
                  role={approvalInfo.role}
                  refetch={refetch}
                  recordId={approvalInfo.recordId}
                  bundle={bundle}
                  site={filterSite}
                  status={status}
                />
              ))}
          </Table>
          <Column alignmentHorizontal="end">
            <Pagination
              showSkipArrows={true}
              numberOfPages={numberOfPages}
              onChange={setCurrentPage}
              currentPage={currentPage}
            />
          </Column>
        </div>
      )}
    </>
  );
};
