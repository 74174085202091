import { ExtractFnReturnType } from 'src/lib/react-query';
import { useQuery } from '@tanstack/react-query';
import { OrchestratorApiService } from 'src/service';
import { GetAuditsResponse } from 'src/features/home/types';
import { BACKEND_PATHS } from 'src/constants/backend-paths';
import { addDays, format, subDays } from 'date-fns';

const getAudits = async (
  filterSite: string,
  assignedUserType: string,
): Promise<GetAuditsResponse> => {
  const currentDate = new Date();
  const startDate = format(subDays(currentDate, 30), 'yyyy-MM-dd');
  const endDate = format(addDays(currentDate, 30), 'yyyy-MM-dd');
  const response = await OrchestratorApiService.get(
    assignedUserType === 'Internal'
      ? BACKEND_PATHS.GET_INTERNAL_AUDITS_FOR_INTERNAL_USERS(filterSite)
      : BACKEND_PATHS.GET_EXTERNAL_AUDITS_FOR_INTERNAL_USERS(
          filterSite,
          startDate,
          endDate,
        ),
  );
  return response.data;
};

type QueryFnType = typeof getAudits;

export const useAuditList = (
  filterSite: string,
  assignedUserType: string,
  isInternal: boolean,
  isInternalLoading: boolean,
) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['getAudits', filterSite, assignedUserType],
    queryFn: () => getAudits(filterSite, assignedUserType),
    enabled: filterSite != '' && !isInternalLoading && isInternal,
  });
};
