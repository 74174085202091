export const HOME_PAGE_STRINGS = {
  HOME: 'gat_home_page_text',
  PICK_A_SITE: 'gat_home_page_pick_a_site_label',
  AUDIT: 'gat_home_page_audit_text',
  ETA: 'gat_home_page_audit_eta_text',
  ROLE: 'gat_home_page_role_text',
  STATUS: 'gat_home_page_status_text',
  AUDIT_FILTER_NO_SITE_ACCESS:
    'gat_home_page_audit_filter_no_site_access_message',
  AUDIT_FILTER_NO_SITE_ACCESS_SUPPLIER:
    'gat_home_page_audit_filter_no_site_access_supplier_message',
  AUDIT_FILTER_GENERIC_ERROR_MESSAGE:
    'gat_home_page_audit_filter_generic_error_message',
  AUDIT_LIST_NO_AUDITS: 'gat_home_page_audit_list_no_audits_message',
  AUDIT_LIST_NO_SUPPLIER_SUBMITTED_AUDITS:
    'gat_home_page_audit_list_no_supplier_submitted_audits',
  AUDIT_LIST_NO_AUDITS_SUPPLIER: 'gat_home_page_audit_list_no_audits_message',
  AUDIT_LIST_NO_INTERNAL_SUBMITTED_AUDITS_SUPPLIER:
    'gat_home_page_audit_list_no_internal_submitted_audits',
  AUDIT_LIST_GENERIC_ERROR_MESSAGE:
    'gat_home_page_audit_list_generic_error_message',
  AUDIT_QUESTION: 'gat_home_page_audit_question_text',
  INFO_TEXT_SUPPLIER: 'gat_home_page_audit_list_supplier_helper_text',
  INFO_TEXT_INTERNAL_USER_INTERNAL_AUDITS:
    'gat_home_page_audit_list_internal_user_help_text_internal_audits',
  INFO_TEXT_INTERNAL_USER_EXTERNAL_AUDITS:
    'gat_home_page_audit_list_internal_user_help_text_external_audits',
  INFO_TEXT_EXTERNAL_USER_INTERNAL_AUDITS:
    'gat_home_page_audit_list_external_user_help_text_internal_audits',
  INFO_TEXT_EXTERNAL_USER_EXTERNAL_AUDITS:
    'gat_home_page_audit_list_external_user_help_text_external_audits',
  START_DATE: 'gat_home_page_start_date',
  END_DATE: 'gat_home_page_end_date',
  YES: 'gat_home_page_exemption_yes',
  NO: 'gat_home_page_exemption_no',
  EXEMPTED: 'gat_home_page_exempted',
  FAILURE_COUNT: 'gat_home_page_audit_failure_count',
  TAB_ACTIONABLE_AUDITS: 'gat_home_page_tab_actionable_audits',
  TAB_AUDITS_FOR_REVIEW: 'gat_home_page_tab_reviewable_audits',
};

export const AUDIT_PAGE_STRING = {
  AUDIT_SUBMITTED_SUCCESSFULLY_MESSAGE:
    'gat_audit_conduct_page_audit_submitted_successfully_message',
  AUDIT_SAVED_SUCCESSFULLY_MESSAGE:
    'gat_audit_conduct_page_audit_saved_successfully_message',
  AUDIT_SAVE_ERROR_MESSAGE: 'gat_audit_conduct_page_audit_save_error_message',
  AUDIT_MANADOTORY_QUESTION_NOT_ANSWERED:
    'gat_audit_conduct_page_audit_manadotory_question_not_answered_message',
  FREE_TEXT_PLACEHOLDER: 'gat_audit_conduct_page_free_text_placeholder',
  NUMBER_PLACEHOLDER: 'gat_audit_conduct_page_number_placeholder',
  MOBILE_BREAKEPOINT_FOR_AUDIT_PAGE: '1000px',
  AUDIT_PAGE_GENERIC_ERROR_MESSAGE:
    'gat_audit_conduct_page_audit_page_generic_error_message',
  AUDIT_FILTER_QUESTION_STATE_LABEL:
    'gat_audit_conduct_page_audit_filter_question_state_label',
  AUDIT_QUESTION_NOT_ANSWERED_MESSAGE:
    'gat_audit_conduct_page_audit_question_not_answered_message',
  AUDITOR_NAME: 'gat_audit_conduct_page_auditor_name_text',
  AUDIT_STATUS: 'gat_audit_conduct_page_audit_status_text',
  AUDIT_TYPE: 'gat_audit_conduct_page_audit_type_text',
  AUDIT_GENERATED_ON: 'gat_audit_conduct_page_audit_generated_on_text',
  AUDIT_GENERATED_BY: 'gat_audit_conduct_page_audit_generated_by_text',
  AUDIT_ETA: 'gat_audit_conduct_page_audit_eta_text',
  AUDIT_LAST_UPDATED_AT: 'gat_audit_conduct_page_audit_last_updated_at_text',
  AUDIT_TOTAL_QUESTIONS: 'gat_audit_conduct_page_audit_total_questions_text',
  AUDIT_SCORE: 'gat_audit_conduct_page_audit_score',
  AUDIT_PENDING_QUESTIONS:
    'gat_audit_conduct_page_audit_pending_questions_text',
  AUDIT_SAVE_DRAFT_BUTTON:
    'gat_audit_conduct_page_audit_save_draft_button_text',
  AUDIT_SUBMIT_DRAFT_BUTTON: 'gat_audit_conduct_page_audit_submit_button_text',
  AUDIT_SUBQUESTION_CANCEL_BUTTON:
    'gat_audit_conduct_page_audit_subquestion_cancel_button_text',
  AUDIT_SUBQUESTION_CONFIRM_BUTTON:
    'gat_audit_conduct_page_audit_subquestions_confirm_button_text',
  AUDIT_MAXIMUM_CHARACTER_LIMIT_REACHED_FOR_TEXT:
    'gat_audit_conduct_page_maximum_limit_reached_for_text',
  AUDIT_SITE: 'gat_audit_conduct_page_auditor_site_text',
  AUDIT_GENERATED_BY_PLACEHOLDER:
    'gat_audit_conduct_page_generated_by_placeholder_text',
  WELCOME_MESSAGE: 'gat_audit_page_welcome_message',
  WELCOME_MESSAGE_ADMIN: 'gat_audit_page_welcome_message_admin',
  WELCOME_MESSAGE_EXTERNAL: 'gat_audit_page_welcome_message_external',
  HELLO_MESSAGE: 'gat_audit_page_hello_message',
  AUDIT_RESPONSE_HEADER_MESSAGE:
    'gat_audit_conduct_page_response_header_message',
  AUDIT_COMPLETION_PERCENTAGE_TEXT:
    'gat_audit_conduct_page_audit_completion_percentage_text',
  AUDIT_SCORE_DEFAULT_TEXT: 'gat_audit_conduct_page_audit_score_default_text',
  ROLE_NAME: 'gat_audit_conduct_page_role_text',
  VENDOR: 'gat_audit_conduct_page_vendor',
  COMPLETED_BY: 'gat_audit_conduct_page_completed_by',
};

export const AUDIT_DETAIL_PAGE_STRING = {
  AUDIT_INFORMATION: 'gat_audit_info_page_text',
  AUDIT_START_BUTTON: 'gat_audit_info_page_audit_start_button_text',
  AUDIT_CONTINUE_BUTTON: 'gat_audit_info_page_audit_continue_button_text',
  AUDIT_CANCEL_BUTTON: 'gat_audit_info_page_audit_cancel_button_text',
  AUDIT_VIEW_BUTTON: 'gat_audit_info_page_audit_view_button_text',
  AUDIT_SCHEDULED_STATUS: 'SCHEDULED',
  AUDIT_DRAFT_STATUS: 'gat_audit_info_page_audit_draft_status_text',
  AUDIT_PERFORM_ACTION: 'Perform',
  AUDIT_VIEW_ACTION: 'ViewAudit',
  AUDIT_RESPONSE_FILTER_ALL:
    'gat_audit_conduct_page_audit_response_filter_all_label',
  AUDIT_RESPONSE_FILTER_PENDING:
    'gat_audit_conduct_page_audit_response_filter_pending_label',
  AUDIT_RESPONSE_FILTER_COMPLETED:
    'gat_audit_conduct_page_audit_response_filter_completed_label',
  AUDIT_RESPONSE_FILTER_PASS:
    'gat_audit_conduct_page_audit_response_filter_pass_label',
  AUDIT_RESPONSE_FILTER_FAIL:
    'gat_audit_conduct_page_audit_response_filter_fail_label',
  AUDIT_RESPONSE_FILTER_NOT_APPLICALE:
    'gat_audit_conduct_page_audit_response_filter_not_applicable_label',

  AUDIT_RESOURCE_TYPE: 'GatAuditV2',
};

export const EXEMPTION_COMPONENT_STRINGS = {
  EXEMPT: 'gat_exemption_component_exempt',
  NON_EXEMPT: 'gat_exemption_component_non_exempt',
  SUBMIT: 'gat_exemption_component_submit',
  CANCEL: 'gat_exemption_component_cancel',
  EXEMPT_AUDITS: 'gat_exemption_component_exempt_audits',
  NON_EXEMPT_AUDITS: 'gat_exemption_component_non_exempt_audits',
  REASON: 'gat_exemption_component_reason',
  COMMENTS: 'gat_exemption_component_comments',
  EXEMPT_FAILURE: 'gat_exemption_component_exempt_failure',
  EXEMPT_SUCCESS: 'gat_exemption_component_exempt_success',
  EXEMPT_PARTIAL_SUCCESS: 'gat_exemption_component_exempt_partial_success',
  NON_EXEMPT_FAILURE: 'gat_exemption_component_non_exempt_failure',
  NON_EXEMPT_SUCCESS: 'gat_exemption_component_non_exempt_success',
  NON_EXEMPT_PARTIAL_SUCCESS:
    'gat_exemption_component_non_exempt_partial_success',
};

export enum ExemptionReasons {
  'gat_exemption_reasons_business_trip' = 'BUSINESS_TRIP',
  'gat_exemption_reasons_vacation' = 'VACATION',
  'gat_exemption_reasons_other' = 'OTHER',
}

export const UPLOAD_COMPONENT_STRINGS = {
  MAX_FILES_ALLOWED_ERROR: 'gat_upload_component_max_files_allowed_error',
  MAX_FILES_UPLOAD_ALLOWED_ERROR:
    'gat_upload_component_max_files_upload_allowed_error',
  MAX_FILES_REACHED_ERROR: 'gat_upload_component_max_files_reached_error',
  UPLOAD_IMAGE_BUTTON_LABEL: 'gat_upload_component_upload_image_button_label',
  UPLOAD_IMAGE_DROPZONE_LABEL:
    'gat_upload_component_upload_image_dropzone_label',
  FILE_SIZE_LIMIT_ERROR: 'gat_upload_component_file_size_limit_error',
  UPLOAD_BUTTON: 'gat_upload_component_upload_button',
  BROWSE_BUTTON: 'gat_upload_component_browse_button',
  UPLOAD_ERROR_MESSAGE: 'gat_upload_component_error_message',
  NUMBER_OF_FILES_UPLOADED: 'gat_upload_component_number_of_files_uploaded',
  SUPPORTED_FILES: 'gat_upload_component_supported_files',
  SIZE_LIMIT: 'gat_upload_component_size_limit',
  GENERIC_ERROR_MESSAGE: 'gat_upload_component_generic_error_message',
  NO_FILE_ADDED: 'gat_upload_component_no_file_added',
  DOWNLOAD_ERROR_MESSAGE: 'gat_upload_component_download_error_message',
  FILE_NAME_ERROR: 'gat_upload_component_file_name_error',
  UPLOAD_SUCCESS_LABEL: 'gat_upload_component_upload_success_label',
  UPLOAD_FAIL_LABEL: 'gat_upload_component_upload_fail_label',
  PREVIEW_ERROR_MESSAGE: 'gat_upload_component_preview_error_message',
  PREVIEW_IMAGE: 'gat_upload_component_preview_image',
  ACCEPT_FILE_TYPE_ERROR: 'gat_upload_component_accept_file_type_error',
};

export enum AUDIT_RESPONSE_FILTER {
  'gat_audit_conduct_page_audit_response_filter_all_label' = 'all',
  'gat_audit_conduct_page_audit_response_filter_pending_label' = 'pending',
  'gat_audit_conduct_page_audit_response_filter_completed_label' = 'completed',
}

export enum COMPLETE_AUDIT_RESPONSE_FILTER {
  'gat_audit_conduct_page_audit_response_filter_all_label' = 'all',
  'gat_audit_conduct_page_audit_response_filter_pass_label' = 'pass',
  'gat_audit_conduct_page_audit_response_filter_fail_label' = 'fail',
  'gat_audit_conduct_page_audit_response_filter_not_applicable_label' = 'not-applicable',
}

export const FAILURE_REMEDIATION_STRINGS = {
  USER: 'gat_failure_remediation_user',
  NOTES: 'gat_failure_remediation_comment',
  REMEDIATION_DATE: 'gat_failure_remediation_remediation_date',
  FAILURE_DETAILS: 'gat_failure_remediation_failure_details',
  SAVE_SUCCESS: 'gat_failure_remediation_save_success',
  FILE_UPLOAD: 'gat_failure_remediation_file_upload',
  REMEDIATION_SUCCESS: 'gat_failure_remediation_success',
  NOT_ANSWERED: 'gat_failure_remediaiton_not_answered',
};
