import { useMutation } from '@tanstack/react-query';
import { BACKEND_PATHS } from 'src/constants/backend-paths';
import { OrchestratorApiService } from 'src/service';

interface Options {
  siteId: string;
  recordId: string;
  consensus: 'APPROVED' | 'REJECTED' | 'REVOKED';
}

const processRoleRequest = async (options: Options) => {
  const response = await OrchestratorApiService.put(
    BACKEND_PATHS.PROCESS_ROLE_REQUEST(options.recordId),
    {
      consensus: options.consensus,
      siteId: options.siteId,
    },
  );
  return response.data;
};

export const useProcessRoleRequest = () => {
  return useMutation({
    mutationFn: processRoleRequest,
  });
};
