import React, { useEffect } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Hub } from 'aws-amplify/utils';
import { useRoutes } from 'react-router-dom';
import { protectedRoutes } from 'src/routes/protected';
import { publicRoutes } from 'src/routes/public';
import { useNavigate } from 'react-router-dom';
import { PAGE_PATHS } from 'src/constants';
import { Loader } from 'src/components';
import { useProfileInfoStore } from 'src/store/profile-info';

const AppRoutes = () => {
  const navigate = useNavigate();
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const { setLocalizationContext } = useProfileInfoStore();
  useEffect(() => {
    Hub.listen('auth', (data) => {
      if (data.payload.event === 'signedIn') {
        setLocalizationContext(null);
        navigate(PAGE_PATHS.HOME);
      } else if (
        data.payload.event === 'signInWithRedirect_failure' ||
        data.payload.event === 'tokenRefresh_failure'
      ) {
        navigate(PAGE_PATHS.WELCOME);
      }
    });
  }, []);

  /**
   * authStatus - enum {authenticated, configuring, unauthenticated}
   * configuring - The configuring state only occurs when the Authenticator is first loading.
   * authenticated - the user is authenticated
   * unauthenticated - the user is not authenticated
   *
   * Rendering a Loader while Amplify is configuring.
   */
  const routes =
    authStatus === 'authenticated' ? protectedRoutes : publicRoutes;
  const element = useRoutes(routes);
  if (authStatus === 'configuring') {
    return <Loader />;
  }
  return <>{element}</>;
};

export default AppRoutes;
