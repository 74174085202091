export const REQUEST_ACCESS_PAGE_STRINGS = {
  REQUEST_ACCESS: 'gat_request_access_page_text',
  PICK_A_SITE: 'gat_request_access_page_pick_site_text',
  PICK_A_ROLE: 'gat_request_access_page_pick_role_text',
  SELECT_A_SITE: 'gat_request_access_page_select_site_label',
  SELECT_A_ROLE: 'gat_request_access_page_select_role_label',
  SUBMIT: 'gat_submit_button_text',
  REQUIRED: 'gat_required_field_text',
  GENERIC_DATA_FETCH_ERROR: 'gat_generic_data_fetch_error_message',
  GENERIC_DATA_POST_ERROR: 'gat_generic_data_post_error_message',
  SUCCESS: 'gat_request_access_submitted_success_message',
};

export const VIEW_ACCESS_PAGE_STRINGS = {
  VIEW_ACCESS: 'gat_view_access_page_text',
  GENERIC_ERROR_MESSAGE: 'gat_view_access_page_generic_error_message',
  ROLE: 'gat_view_access_page_role_label',
  SITE: 'gat_view_access_page_site_label',
  STATUS: 'gat_view_access_page_status_text',
  COMPANY: 'gat_view_access_page_company_name_text',
  EMPLOYEE_INFO: 'gat_view_access_page_employee_info_text',
  SERVICE: 'gat_view_access_page_service_text',
  NO_DATA: 'gat_no_data_returned',
  POC: 'gat_view_access_page_poc_text',
  POC_INFO_TEXT: 'gat_view_access_page_poc_info_text',
};

export const APPROVE_ACCESS_PAGE_STRINGS = {
  APPROVE_ACCESS: 'gat_approve_access_page_approve_text',
  PICK_A_SITE: 'gat_approve_access_page_pick_site_label',
  SITE_FETCH_ERROR: 'gat_approve_access_page_site_fetch_error_message',
  APPROVAL_REQUESTS_ERROR:
    'gat_approve_access_page_approval_request_error_message',
  NO_REQUESTS_FOUND: 'gat_approve_access_page_no_request_found_message',
  ROLE: 'gat_approve_access_page_role_text',
  USER: 'gat_approve_access_page_user_text',
  ACTION: 'gat_approve_access_page_action_text',
  PROCESS_SUCCESFUL: 'gat_approve_access_page_process_succesful_message',
  PROCESS_FAILURE: 'gat_approve_access_page_process_failure_message',
  APPROVE: 'gat_approve_access_page_text',
  REJECT: 'gat_approve_access_page_reject_text',
  REVOKE: 'gat_user_access_page_revoke_text',
  STATUS: 'gat_advanced_search_page_status_text',
  APPROVED_STATUS: 'gat_user_status_approved',
  REJECTED_STATUS: 'gat_user_status_rejected',
  REVOKED_STATUS: 'gat_user_status_revoked',
  PENDING_STATUS: 'gat_user_status_pending',
  INFO_MESSAGE: 'gat_site_based_lookup_info_message',
};

export const USER_ACCESS_PAGE_STRINGS = {
  USER_ACCESS: 'gat_user_access_page_action_text',
  SEARCH_USER: 'gat_user_access_page_search_user_label',
  SEARCH_USER_PLACEHOLDER: 'gat_user_access_page_search_user_placeholder_text',
  INVALID_EMAIL_ADDRESS: 'gat_user_access_page_invalid_email_adress_message',
  USER_ACCESS_FETCH_ERROR:
    'gat_user_access_page_user_access_fetch_error_message',
  SITE: 'gat_user_access_page_site_text',
  ROLE: 'gat_user_access_page_role_text',
  STATUS: 'gat_user_access_page_status_text',
  ACTION: 'gat_user_access_page_action_text',
  REVOKE: 'gat_user_access_page_revoke_text',
  PROCESS_SUCCESFUL: 'gat_user_access_page_process_succesful_message',
  PROCESS_FAILURE: 'gat_user_access_page_process_failure_message',
  APPROVE: 'gat_approve_access_page_text',
  REJECT: 'gat_approve_access_page_reject_text',
  PENDING_STATUS: 'gat_user_status_pending',
  NO_DATA: 'gat_no_data_returned',
  INFO_MESSAGE: 'gat_user_based_lookup_info_message',
};

export const GRANT_ACCESS_PAGE_STRINGS = {
  GRANT_ACCESS: 'gat_grant_access_page_text',
  EMAIL: 'gat_grant_access_page_email_text',
  ROLE: 'gat_grant_access_page_role_text',
  SITE: 'gat_grant_access_page_site_text',
  SUBMIT: 'gat_grant_access_page_submit_text',
  CONFIG_FETCH_ERROR: 'gat_grant_access_page_config_fetch_error_text',
  REQUIRED: 'gat_grant_access_page_required_field_text',
  INCORRECT_EMAIL: 'gat_grant_access_page_incorrect_email_text',
  FORM_REQUIRED: 'gat_grant_access_page_form_required_text',
  SUCCESS: 'gat_grant_access_page_success_text',
  FAILURE: 'gat_grant_access_page_failure_text',
};

export const SUPPLIER_ACCESS_PAGE_STRINGS = {
  SUPPLIER: 'gat_supplier_access_supplier_text',
  SUPPLIER_ACCESS: 'gat_supplier_access_sidemenu_text',
  SUPPLIER_USER_ONBOARDING: 'gat_supplier_access_supplier_onboarding_text',
  SUPPLIER_COMPANY_ONBOARDING:
    'gat_supplier_access_supplier_compnay_onboarding_text',
  NAME: 'gat_supplier_access_supplier_company_name_text',
  COUPA_ID: 'gat_supplier_access_coupa_id_text',
  SUBMIT: 'gat_supplier_access_submit_text',
  COUNTRY: 'gat_supplier_access_country_text',
  REQUIRED: 'gat_supplier_access_required_field_text',
  SUPPLIER_COMPANY_ONBOARDING_SUCCESS:
    'gat_supplier_access_supplier_company_onboarding_success_text',
  SUPPLIER_COMPANY_ONBOARDING_FAILURE:
    'gat_supplier_access_supplier_company_failure_text',
  EMPLOYEE: 'gat_supplier_access_employee_text',
  MANAGER: 'gat_supplier_access_manager_text',
  EMAIL: 'gat_supplier_access_email_text',
  INCORRECT_EMAIL: 'gat_supplier_access_incorrect_email_text',
  FIRST_NAME: 'gat_supplier_access_first_name_text',
  LAST_NAME: 'gat_supplier_access_last_name_text',
  EMPLOYEE_TYPE: 'gat_supplier_access_employee_type_text',
  RESEND: 'gat_supplier_access_resend_text',
  FALSE: 'gat_supplier_access_false_text',
  TRUE: 'gat_supplier_access_true_text',
  SUPPLIER_SELECT: 'gat_supplier_access_supplier_select_text',
  SUPPLIER_USER_ONBOARDING_SUCCESS:
    'gat_supplier_access_supplier_user_onboarding_success_text',
  SUPPLIER_USER_ONBOARDING_FAILURE:
    'gat_supplier_access_supplier_user_onboarding_failure_text',
  SUPPLIER_USER_ONBOARDING_COUNTRY_REQUIRED:
    'gate_supplier_access_country_required_text',
  SUPPLIER_ACCESS_DATA_FETCH_ERROR: 'gat_generic_data_fetch_error_message',
};
