import { axios } from 'src/lib/axios';
import { AxiosProgressEvent } from 'axios';
import {
  BINARY_OCTET_STREAM,
  CONTENT_DISPOSITION,
  CONTENT_TYPE,
} from 'src/constants/backend-paths';

interface S3Client {
  put(
    path: string,
    body: any,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
    abortController?: AbortController,
  ): Promise<any>;
}

export const S3Service: S3Client = {
  async put(path, body, onUploadProgress, abortController) {
    return axios.put(path, body, {
      headers: {
        [CONTENT_TYPE]: BINARY_OCTET_STREAM,
        [CONTENT_DISPOSITION]: `attachment; fileName="${encodeURIComponent(
          body.name,
        )}"`,
      },
      onUploadProgress: onUploadProgress,
      signal: abortController?.signal,
    });
  },
};
