import { useEffect, useState } from 'react';
import {
  AsyncMessageBundleManager,
  createAsyncMessageBundleManager,
  LocalizationContext,
} from '@amzn/arb-tools';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import arbManifest from '../../build/i18n/arbManifest';
import { LocalizationProps } from 'src/interfaces/BundleInterface';
import {
  bundle_name,
  DEFAULT_LC_BUILDER,
  DEFAULT_LOCALE,
  getLocale,
} from 'src/constants/localization';
import { useProfileInfoStore } from 'src/store/profile-info';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { useAuthenticator } from '@aws-amplify/ui-react';

const createMbm = (locale: string): AsyncMessageBundleManager => {
  const localizationContextProvider = () =>
    DEFAULT_LC_BUILDER.withLocale(locale).build();

  return createAsyncMessageBundleManager({
    arbManifest,
    localizationContextProvider,
    urlPrefix: '/i18n',
  });
};

export const useLocalization = () => {
  const { localizationContext, setBundle, setLocalizationContext, bundle } =
    useProfileInfoStore();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { authStatus } = useAuthenticator();
  // fetch first time from the auth token.
  useEffect(() => {
    const fetchBundler = (curLocale: string) => {
      const mbm = createMbm(curLocale);
      const currentLocalizationContext =
        DEFAULT_LC_BUILDER.withLocale(curLocale).build();
      setLocalizationContext(DEFAULT_LC_BUILDER.withLocale(curLocale).build());
      getBundle(bundle_name, currentLocalizationContext, mbm)
        .then((newBundle) => {
          setBundle(newBundle as LocalizationProps);
        })
        .catch((error) => {
          console.error('Error fetching bundle:', error);
        });
    };
    if (authStatus === 'authenticated') {
      fetchUserAttributes()
        .then((attributes) => {
          fetchBundler(getLocale(attributes));
        })
        .catch(() => {
          fetchBundler(DEFAULT_LOCALE);
        });
    } else if (authStatus === 'unauthenticated') {
      fetchBundler(DEFAULT_LOCALE);
    }
  }, [authStatus, setLocalizationContext, setBundle, setIsLoading]);

  /**
   * Translation ID shows up initially (for 200 ms) instead of the
   * formatted message, this hack enables us to avoid seeing
   * that incorrect message.
   */
  useEffect(() => {
    if (
      bundle &&
      localizationContext &&
      bundle.formatMessage('gat_submit_button_text') !==
        'gat_submit_button_text'
    ) {
      setIsLoading(false);
    }
  }, [bundle, localizationContext, setIsLoading]);

  return { localizationContext, bundle, isLoading };
};

const getBundle = async (
  bundleName: string,
  localizationContext: LocalizationContext,
  mbm: AsyncMessageBundleManager,
): Promise<LocalizationProps> => {
  return new Promise((resolve, reject) => {
    mbm
      .getBundle(bundleName, localizationContext)
      .then((bundle) => {
        resolve(bundle);
      })
      .catch((error) => {
        console.error('Error fetching bundle:', error);
        reject(error);
      });
  });
};
