import React, { useEffect, useState } from 'react';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import Text from '@amzn/meridian/text';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button';
import { useNavigate } from 'react-router-dom';
import { fetchUserAttributes } from 'aws-amplify/auth';
import {
  COGNITO_ATTRIBUTES,
  PROFILE_PAGE_STRINGS,
} from 'src/features/profile/constants';
import { PAGE_PATHS } from 'src/constants';
import { useProfileInfoStore } from 'src/store/profile-info';

const withCountryLanguageCheck = (Component: any) => {
  return (props: any) => {
    const [message, setMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const { bundle } = useProfileInfoStore();
    const navigate = useNavigate();
    useEffect(() => {
      // Check if country and language are present in the auth header
      fetchUserAttributes().then((response) => {
        if (
          !response[COGNITO_ATTRIBUTES.COUNTRY_ATTR] ||
          !response[COGNITO_ATTRIBUTES.LANGUAGE_ATTR]
        ) {
          setShowModal(true);
          if (!response[COGNITO_ATTRIBUTES.COUNTRY_ATTR])
            setMessage(PROFILE_PAGE_STRINGS.COUNTRY_NOT_SELECTED_MSG);
          else setMessage(PROFILE_PAGE_STRINGS.LANGUAGE_NOT_SELECTED_MSG);
        }
      });
    }, []);
    const handleRedirectToProfile = () => {
      setShowModal(false);
      navigate(PAGE_PATHS.EDIT_PROFILE);
    };
    return (
      <>
        <Component {...props} />
        {showModal && (
          <Modal
            title={bundle.formatMessage(PROFILE_PAGE_STRINGS.POP_UP_TITLE)}
            open={showModal}
          >
            <Text id="modal-description">{bundle.formatMessage(message)}</Text>
            <ModalFooter>
              <Row alignmentHorizontal="center" widths="fit">
                <Button
                  type="tertiary"
                  size="small"
                  onClick={handleRedirectToProfile}
                >
                  {bundle.formatMessage(PROFILE_PAGE_STRINGS.REDIRECT)}
                </Button>
              </Row>
            </ModalFooter>
          </Modal>
        )}
      </>
    );
  };
};

export default withCountryLanguageCheck;
