import React from 'react';

export const ErrorLogo = () => {
  return (
    <svg
      width={85}
      height={38}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.23 7.913 7.91 3.23c.15-.15.35-.23.57-.23h7.05c.21 0 .42.08.57.23l4.67 4.673c.15.15.23.35.23.57v7.054c0 .21-.08.42-.23.57L16.1 20.77c-.15.15-.35.23-.57.23H8.47a.81.81 0 0 1-.57-.23l-4.67-4.673a.793.793 0 0 1-.23-.57V8.473c0-.21.08-.42.23-.57v.01Z"
        fill="#000000"
        fillOpacity={0.16}
        stroke="#000000"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinejoin="round"
      />
      <path
        d="M12 16h.008M12 8v5"
        stroke="#000000"
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
    </svg>
  );
};
