import { create } from 'zustand';
import { LocalizationContext } from '@amzn/arb-tools';
import { mbmOptions } from 'src/constants/localization';
import { LocalizationProps } from 'src/interfaces/BundleInterface';

interface IProfileInfoStore {
  localizationContext: LocalizationContext | null;
  setLocalizationContext: (
    localizationContext: LocalizationContext | null,
  ) => void;
  mbmOptions: {
    arbManifest: any;
    defaultLocalizationContext: LocalizationContext;
    urlPrefix: string;
  };
  setMbmOptions: (options: {
    arbManifest: any;
    defaultLocalizationContext: LocalizationContext;
    urlPrefix: string;
  }) => void;
  bundle: LocalizationProps;
  setBundle: (bundle: LocalizationProps) => void;
}

export const useProfileInfoStore = create<IProfileInfoStore>((set) => ({
  localizationContext: null,
  setLocalizationContext: (
    newLocalizationContext: LocalizationContext | null,
  ) => {
    set({
      localizationContext: newLocalizationContext,
    });
  },
  mbmOptions: mbmOptions,
  setMbmOptions: (options: {
    arbManifest: any;
    defaultLocalizationContext: LocalizationContext;
    urlPrefix: string;
  }) => {
    set({
      mbmOptions: options,
    });
  },
  bundle: {
    getMessage: (id: string) => id,
    formatMessage: (id: string) => id,
  },
  setBundle: (newBundle) => {
    set({
      bundle: newBundle,
    });
  },
}));
