import { ExtractFnReturnType } from 'src/lib/react-query';
import { useQuery } from '@tanstack/react-query';
import { OrchestratorApiService } from 'src/service';
import { BACKEND_PATHS } from 'src/constants/backend-paths';
import { GetUserRolesResponse } from '../types';

const APPROVED_USER_STATUS = 'APPROVED';

export const getUserAuthorization = async (): Promise<GetUserRolesResponse> => {
  const response = await OrchestratorApiService.get(BACKEND_PATHS.USER_ROLES);
  const data: GetUserRolesResponse = response.data;
  data.userRoles.filter((userRole) => userRole.status === APPROVED_USER_STATUS);
  return data;
};

type QueryFnType = typeof getUserAuthorization;

export const useUserAuthorization = () => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['userRolesAuthorization'],
    queryFn: () => getUserAuthorization(),
  });
};

export default useUserAuthorization;
