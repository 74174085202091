import { css } from '@emotion/css';
import { colorGray200 } from '@amzn/meridian-tokens/base/color';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import { useProfileInfoStore } from 'src/store/profile-info';
import { GAT_STRINGS } from 'src/features/auth/constants/static';
import Text from '@amzn/meridian/text';

const footerStyle = css({
  borderTop: `1px solid ${colorGray200}`,
  position: 'sticky',
  left: 0,
});

export const AppFooter = () => {
  const { bundle } = useProfileInfoStore();

  return (
    <div className={footerStyle}>
      <Column
        alignmentHorizontal="center"
        backgroundColor="alternateSecondary"
        spacingInset="400 500"
      >
        <Row alignmentHorizontal="center" maxWidth="100%">
          <Text>{bundle.formatMessage(GAT_STRINGS.GAT_FOOTER_TEXT)}</Text>
        </Row>
      </Column>
    </div>
  );
};
