import { useMutation } from '@tanstack/react-query';
import { BACKEND_PATHS } from 'src/constants/backend-paths';
import { OrchestratorApiService } from 'src/service';

interface Options {
  body: {
    roleId: string;
    siteId: string;
  };
}

const createRoleRequest = async (options: Options) => {
  const response = await OrchestratorApiService.post(
    BACKEND_PATHS.REQUEST_ROLE,
    options.body,
  );
  return response.data;
};

export const useCreateRoleRequest = () => {
  return useMutation({
    mutationFn: createRoleRequest,
  });
};
