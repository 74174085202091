import React from 'react';
import { default as MeridianLoader } from '@amzn/meridian/loader';
import { CentralizeLoader } from 'src/styles/styles';

export const Loader: React.FC<{
  loaderStyle?: string;
}> = ({ loaderStyle }) => {
  return (
    <div className={loaderStyle ? loaderStyle : CentralizeLoader}>
      <MeridianLoader />
    </div>
  );
};
