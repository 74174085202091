import { fetchUserAttributes } from 'aws-amplify/auth';
import { useEffect, useState } from 'react';
import { COGNITO_ATTRIBUTES } from 'src/features/profile/constants';
export const useCountry = () => {
  const [authTokenPayload, setAuthTokenPayload] = useState<{
    data: string | undefined;
    loading: boolean;
  }>({ data: undefined, loading: true });
  useEffect(() => {
    fetchUserAttributes()
      .then((authSession) => {
        const countryCode = authSession[COGNITO_ATTRIBUTES.COUNTRY_ATTR];
        setAuthTokenPayload({
          data: countryCode as string,
          loading: false,
        });
      })
      .catch(() => {
        setAuthTokenPayload({
          data: undefined,
          loading: false,
        });
      });
  }, []);
  return {
    country: authTokenPayload.data,
    isLoading: authTokenPayload.loading,
  };
};
