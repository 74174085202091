import useBreakpoint from '@amzn/meridian/use-breakpoint';

// Device with width less than 600 is being considered as a Mobile
const defaultOverlayBreakpoint = '600px';

export const useIsMobile = (overlayBreakpoint = defaultOverlayBreakpoint) => {
  const breakpointState = useBreakpoint(overlayBreakpoint);
  const isMobile = breakpointState === 'smaller';
  return isMobile;
};
