import { create } from 'zustand';

export interface IToast {
  id: string;
  type: string;
  message: string;
  timeout: number;
}

interface IToastStore {
  toasts: Array<IToast>;
  addToast: (newToast: IToast) => void;
  removeToast: (id: string) => void;
}

export const useToastStore = create<IToastStore>((set) => ({
  toasts: [],
  addToast: (toast) =>
    set((state) => ({
      toasts: [...state.toasts, toast],
    })),
  removeToast: (id) =>
    set((state) => ({
      toasts: state.toasts.filter((t) => t.id !== id),
    })),
}));
