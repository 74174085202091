export enum AuditGeneratedOn {
  'DAILY' = 'gat_site_info_page_daily',
  'WEEKLY' = 'gat_site_info_page_weekly',
  'MONTHLY' = 'gat_site_info_page_monthly',
}
export enum SortColumns {
  'gat_site_info_page_audit' = 'auditType',
  'gat_site_info_page_frequency' = 'auditCadence',
  'gat_site_info_page_role' = 'roleName',
}
export const SITE_INFO_PAGE_STRINGS = {
  SITE_INFO: 'gat_site_info_page_title',
  GENERIC_ERROR_MESSAGE: 'gat_site_info_page_site_fetch_error_message',
  NO_SITE_ACCESS: 'gat_site_info_page_no_site_access',
  SITE: 'gat_site_info_page_site',
  AUDIT: 'gat_site_info_page_audit',
  FREQUENCY: 'gat_site_info_page_frequency',
  SUPPLIER_NAME: 'gat_site_info_page_supplier_name',
  SUPPLIER_COUPA_ID: 'gat_site_info_page_supplier_coupa_id',
  AUDIT_GENERATED_ON: 'gat_site_info_page_audit_generated_on',
  ROLE: 'gat_site_info_page_role',
  NO_SITE_INFO: 'gat_site_info_page_no_site_info',
  SORT_BY: 'gat_site_info_page_sort_by',
  MISSING_SUPPLIER_MESSAGE: 'gat_site_info_page_missing_supplier_message',
  INFO_MESSAGE: 'gat_site_info_page_info_message',
};
