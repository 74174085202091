import React from 'react';
import Column from '@amzn/meridian/column';
import { ColumnAlignmentHorizontal } from '@amzn/meridian/column/column';
import Heading from '@amzn/meridian/heading';
import { Loader } from 'src/components';

type PageLayoutProps = {
  title: string;
  alignmentHorizontal?: string;
  loading?: boolean;
  spacing?: string;
  spacingInset?: string;
  maxWidth?: number | string;
  width?: number | string;
  children: string | React.ReactElement;
};

export const PageLayout = ({
  title,
  children,
  alignmentHorizontal = 'center',
  loading = false,
  spacing = '500',
  spacingInset = '500 500 600',
  maxWidth = '100%',
  width = 950,
}: PageLayoutProps) => {
  return (
    <Column
      alignmentHorizontal={alignmentHorizontal as ColumnAlignmentHorizontal}
      spacingInset={spacingInset}
    >
      <Column width={width} maxWidth={maxWidth} spacing={spacing}>
        <Heading level={1} type={'d100'} fontFamily={'amazonEmber'}>
          {title}
        </Heading>
      </Column>
      {loading ? <Loader /> : children}
    </Column>
  );
};
