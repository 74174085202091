import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import MastHead, {
  MastheadMenuButton,
  MastheadTitle,
} from '@amzn/meridian/masthead';
import Text from '@amzn/meridian/text';
import Theme from '@amzn/meridian/theme';
import Row from '@amzn/meridian/row';
import { useSideMenuStore } from 'src/store/side-menu-store';
import { useIsMobile } from 'src/hooks';
import { AmazonIcon } from 'src/assets';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { GAT_STRINGS } from 'src/features/auth/constants/static';

export const AppMastHead = () => {
  const navigate = useNavigate();
  const { flipIsSideMenuOpen } = useSideMenuStore();
  const { authStatus } = useAuthenticator();
  const isMobile = useIsMobile();

  const onClickMenuButton = useCallback(
    () => flipIsSideMenuOpen(),
    [flipIsSideMenuOpen],
  );

  return (
    <Theme mode={'dark'}>
      <MastHead backgroundColor="#002A3C">
        {authStatus === 'authenticated' && (
          <MastheadMenuButton onClick={onClickMenuButton} />
        )}
        <MastheadTitle href="/" onClick={navigate}>
          <Row>
            <AmazonIcon />
            <Text fontFamily="bookerly" type="b500">
              {isMobile
                ? GAT_STRINGS.GAT_MOBILE_TEXT
                : GAT_STRINGS.GAT_DESKTOP_TEXT}
            </Text>
          </Row>
        </MastheadTitle>
      </MastHead>
    </Theme>
  );
};
