export enum AuditStatus {
  'gat_audit_status_not_started' = 'DRAFT',
  'gat_audit_status_in_progress' = 'IN_PROGRESS',
  'gat_audit_status_completed' = 'SUBMITTED',
  'gat_audit_status_expired' = 'EXPIRED',
}

export enum AuditStatusReverseMapping {
  'SCHEDULED' = 'gat_audit_status_not_started',
  'DRAFT' = 'gat_audit_status_not_started',
  'IN_PROGRESS' = 'gat_audit_status_in_progress',
  'SUBMITTED' = 'gat_audit_status_completed',
  'EXPIRED' = 'gat_audit_status_expired',
}

export const ADVANCED_SEARCH_PAGE_STRINGS = {
  AUDIT_SEARCH: 'gat_advanced_search_page_text',
  GENERIC_ERROR_MESSAGE: 'gat_advanced_search_page_site_fetch_error_message',
  NO_SITE_ACCESS: 'gat_advanced_search_page_no_site_access_message',
  AUDIT_LIST_MESSAGE: 'gat_advanced_search_page_audit_list_message',
  AUDIT_LIST_MESSAGE_ADMIN: 'gat_advanced_search_page_audit_list_message_admin',
  NO_AUDITS: 'gat_advanced_search_page_no_audits_message',
  SITE: 'gat_advanced_search_page_site_text',
  AUDIT_TYPE: 'gat_advanced_search_page_audit_type_text',
  STATUS: 'gat_advanced_search_page_status_text',
  ASSIGNED_USER_TYPE: 'gat_advanced_search_page_assigned_user_type_text',
  START_DATE: 'gat_advanced_search_page_start_date_text',
  END_DATE: 'gat_advanced_search_page_end_date_text',
  DATE_RANGE: 'gat_advanced_search_page_date_range_text',
  DATE_FORMAT: '(MM/DD/YYYY)',
  SITE_FETCH_ERROR: 'gat_advanced_search_page_site_fetch_error_message',
  SEARCH: 'gat_advanced_search_page_search_text',
  CLEAR_FILTERS: 'gat_advanced_search_page_clear_filters_text',
  REQUIRED: '*',
  REQUIRED_FIELDS: 'gat_advanced_search_page_required_fields_text',
  SITE_INFO: 'gat_advanced_search_page_site_info_text',
  AUDIT_TYPE_INFO: 'gat_advanced_search_page_audit_type_info_text',
  STATUS_INFO: 'gat_advanced_search_page_status_info_text',
  ASSIGNED_USER_TYPE_INFO:
    'gat_advanced_search_page_assigned_user_type_info_text',
  DATE_RANGE_INFO: 'gat_advanced_search_page_date_range_info_text',
  EXEMPTED_INFO: 'gat_advanced_search_page_exempted_info',
  USER_TYPE_EXTERNAL: 'gat_advanced_search_page_user_type_external',
  USER_TYPE_INTERNAL: 'gat_advanced_search_page_user_type_internal',
};

export const ADVANCED_SEARCH_PAGE_CONSTANTS = {
  FILTER_WIDTH_MOBILE: 140,
  FILTER_WIDTH: 200,
};
