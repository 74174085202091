import React from 'react';
import { createRoot } from 'react-dom/client';
import { Loader } from 'src/components';
import AppRoutes from 'src/routes';
import { Amplify } from 'aws-amplify';
import { getCognitoConfig } from 'src/constants/cognito-config';
import { Authenticator } from '@aws-amplify/ui-react';
import { BrowserRouter as Router } from 'react-router-dom';
import '@amzn/meridian-tokens/base/font/amazon-ember.css';
import '@amzn/meridian-tokens/base/font/bookerly.css';
import '@amzn/meridian-tokens/base/font/amazon-ember-monospace.css';
import { queryClient } from 'src/lib/react-query';
import { QueryClientProvider } from '@tanstack/react-query';
import { mbmOptions } from 'src/constants/localization';
import { MbmProvider } from '@amzn/react-arb-tools';
import { useLocalization } from 'src/hooks/useLocalization';
import { ErrorBoundary } from 'src/components/ErrorBoundary';

Amplify.configure(getCognitoConfig());

const Root = () => {
  const { localizationContext, bundle, isLoading } = useLocalization();
  return (
    <MbmProvider {...mbmOptions}>
      <QueryClientProvider client={queryClient}>
        <Router>
          {isLoading || !(localizationContext && bundle) ? (
            <Loader />
          ) : (
            <AppRoutes />
          )}
        </Router>
      </QueryClientProvider>
    </MbmProvider>
  );
};

const container = document.getElementById('root');
/* eslint-disable  @typescript-eslint/no-non-null-assertion */
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Authenticator.Provider>
        <React.Suspense fallback={<Loader />}>
          <Root />
        </React.Suspense>
      </Authenticator.Provider>
    </ErrorBoundary>
  </React.StrictMode>,
);
